.nirvagam {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

div h1 {
  color: #333;
  font-size: 32px;
  margin-bottom: 10px;
}

div p {
  color: #555;
  font-size: 16px;
  margin-bottom: 10px;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

a {
  color: #0066cc;
}
