.ithaz {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.image {
  margin: 10px;
}
