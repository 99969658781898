.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three items per row */
  gap: 20px; /* Gap between grid items */
  padding: 20px;
}
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

/* Responsive styles */
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery {
    grid-template-columns: 1fr; /* One item per row on even smaller screens */
  }
}
